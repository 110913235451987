<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <EstablishmentRoleForm
      v-if="!isLoading"
      :establishmentRole="establishmentRole"
      :imageSrc="imageSrc"
      v-on:addOrUpdateEstablishmentRole="addOrUpdateEstablishmentRole()"
      :submitName="$t('edit')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import EstablishmentRoleImg from "@/assets/images/establishment-role.svg";
import EstablishmentRoleForm from "./../../../components/settings/establishmentRoles/EstablishmentRoleForm.vue";
import EstablishmentRole from "./../../../models/settings/establishmentRoles/EstablishmentRole";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    EstablishmentRoleForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: EstablishmentRoleImg,
      establishmentRole: new EstablishmentRole(),
    };
  },
  props: ["establishmentRoleToken"],
  methods: {
    async addOrUpdateEstablishmentRole() {
      this.isLoading = true;
      try {
        const response =
          await this.establishmentRole.addOrUpdateEstablishmentRole(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.establishmentRole.setInitialValue();
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "EstablishmentRoles" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getEstablishmentRoleDetails() {
      this.isLoading = true;
      this.establishmentRole.establishmentRoleToken =
        this.establishmentRoleToken;
      try {
        let response = await this.establishmentRole.getEstablishmentRoleDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.establishmentRole.fillData(response.data.establishmentRoles);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
        } else {
          this.showMsg(response.data.msg);
          this.$router.push({ name: "EstablishmentRoles" }).catch(() => {});
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.$router.push({ name: "EstablishmentRoles" }).catch(() => {});
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEstablishmentRoleDetails();
  },
};
</script>
